import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Image as CloudinaryImage, Transformation } from 'cloudinary-react'
import { path } from 'ramda'
import { CLOUDINARY_UPLOAD_PRESET, superSupportAccessEmails } from 'constants/general'
import { subscribe } from 'react-contextual'
import { useBreakpoints } from '../../../../hooks'
import Navigation from '../Navigation/Navigation'
import { Image } from '../../..'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import DashboardIcon from '../../../../../theme/icons-v2/Descriptive/Business.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import PaymentsIcon from '../../../../../theme/icons-v2/Descriptive/Payments.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import PropertyIcon from '../../../../../theme/icons-v2/Property/Properties.svg'
// import ContactsIconActive from '../../../../../theme/icons-v2/User/Contacts-fill.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import ContactsIcon from '../../../../../theme/icons-v2/User/Contacts.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import ReportsIcon from '../../../../../theme/icons-v2/Descriptive/Reports.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import SettingsIcon from '../../../../../theme/icons-v2/Controls/Settings.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import LogoutIcon from '../../../../../theme/icons-v2/Security/Logout.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import PropertiesIcon from '../../../../../theme/icons-v2/Property/Complex.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import CommunicationsIcon from '../../../../../theme/icons-v2/Doc/Email.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import InvoiceIcon from '../../../../../theme/icons-v2/Doc/Invoice.svg'
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '../../../../../theme/icons-v2/... Remove this comment to see the full error message
import HotIcon from '../../../../../theme/icons-v2/Other/Hot.svg'

import styles from './Sidebar.module.scss'
import { userApiEvents } from '../../../../../modules/api/user'
import { agencyApiSelectors } from '../../../../../modules/api/agency'
import { SupportStaffProvider } from '../../../../../modules/supportStaff'
import { ROUTES } from 'constants/routes'

type TProps = {
  redirect: (path: string) => void
  pathname: string
  currentAgencyId: string
  userRole: string
  isValidSupportStaff: boolean
  isAccessAllowed: (emails: string[]) => boolean
}

const Sidebar = ({ redirect, pathname, currentAgencyId, userRole, isValidSupportStaff, isAccessAllowed }: TProps) => {
  const dispatch = useDispatch()
  const { isMobile } = useBreakpoints()

  const navSections = [
    [
      {
        text: 'Dashboard',
        route: '/dashboard',
        icon: (
          <i>
            <img src={DashboardIcon} alt="Dashboard" />
          </i>
        ),
      },
      {
        text: 'Invoices',
        basepath: '/invoices',
        route: '/invoices/drafts',
        disabled: !currentAgencyId,
        icon: (
          <i>
            <img src={InvoiceIcon} alt="Invoices" />
          </i>
        ),
        subNavItems: [
          {
            text: 'Drafts',
            route: '/invoices/drafts',
            disabled: !currentAgencyId,
          },
          {
            text: 'Active',
            route: '/invoices/active',
            disabled: !currentAgencyId,
          },
          {
            text: 'History',
            route: '/invoices/history',
            disabled: !currentAgencyId,
          },
        ],
      },
    ],
    [
      {
        text: 'Payments',
        route: '/payments',
        disabled: !currentAgencyId,
        icon: (
          <i>
            <img src={PaymentsIcon} alt="Payments" />
          </i>
        ),
      },
      {
        text: 'Leases',
        route: '/leases',
        disabled: !currentAgencyId,
        icon: (
          <i>
            <img src={PropertyIcon} alt="Leases" />
          </i>
        ),
      },
      {
        text: 'Properties',
        route: '/properties',
        disabled: !currentAgencyId,
        icon: (
          <i>
            <img src={PropertiesIcon} alt="Properties" />
          </i>
        ),
      },
      {
        text: 'Contacts',
        route: '/contacts',
        disabled: !currentAgencyId,
        icon: (
          <i>
            <img src={ContactsIcon} alt="Contacts" />
          </i>
        ),
      },
      {
        text: 'Communications',
        basepath: '/communications',
        route: '/communications/email',
        disabled: !currentAgencyId,
        icon: (
          <i>
            <img src={CommunicationsIcon} alt="Communications" />
          </i>
        ),
        subNavItems: [
          {
            text: 'Email Log',
            route: '/communications/email',
            disabled: !currentAgencyId,
          },
        ],
      },
      {
        text: 'Integrations',
        route: '/integrations',
        disabled: !currentAgencyId,
        icon: (
          <i>
            <img src={HotIcon} alt="Integrations" />
          </i>
        ),
      },
      {
        text: 'Reporting',
        route: '/reporting',
        disabled: !currentAgencyId,
        icon: (
          <i>
            <img src={ReportsIcon} alt="Reporting" />
          </i>
        ),
      },
    ],
    userRole === 'Owner'
      ? [
          {
            text: 'Settings',
            route: '/settings/profile',
            basepath: '/settings',
            icon: (
              <i>
                <img src={SettingsIcon} alt="Icon" />
              </i>
            ),
            subNavItems: [
              {
                text: 'Profile',
                route: '/settings/profile',
              },
              {
                text: 'Business Details',
                route: '/settings/business-details',
              },
              {
                text: 'Branding',
                route: '/settings/branding',
                disabled: !currentAgencyId,
              },
              {
                text: 'Users',
                route: '/settings/users',
              },
              {
                text: 'Book Segments',
                route: '/settings/segments',
                disabled: !currentAgencyId,
              },
            ],
          },
        ]
      : [
          {
            text: 'Profile',
            route: '/settings/profile',
            basepath: '/settings/profile',
            icon: (
              <i>
                <img src={SettingsIcon} alt="Icon" />
              </i>
            ),
          },
        ],
    [
      {
        text: 'Admin',
        route: ROUTES.suspenseAccount,
        basepath: '/admin',
        icon: (
          <i>
            <img src={SettingsIcon} alt="Icon" />
          </i>
        ),
        isHidden: !isValidSupportStaff || !isAccessAllowed(superSupportAccessEmails),
        subNavItems: [
          {
            text: 'Suspense Account',
            route: ROUTES.suspenseAccount,
          },
          {
            text: 'Wallet Transfer',
            route: ROUTES.walletTransfer,
          },
          {
            text: 'Agencies',
            route: ROUTES.agencies,
          },
          {
            text: 'Reporting',
            route: ROUTES.adminReporting,
          },
        ],
      },
    ],
    [
      {
        text: 'Logout',
        onClick: () => dispatch(userApiEvents.logout_request()),
        icon: (
          <i>
            <img src={LogoutIcon} alt="icon" />
          </i>
        ),
      },
    ],
    // @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable.
  ].map(sections => sections.filter((section: any) => path(['isHidden'], section) !== true))

  const logoUrl = useSelector(state => agencyApiSelectors.getAgencyLogo(state)(currentAgencyId))

  return (
    <div className={styles.root}>
      {!isMobile && (
        <div className={styles.logo}>
          <button onClick={() => redirect('/dashboard')}>
            {logoUrl ? (
              <CloudinaryImage cloudName={CLOUDINARY_UPLOAD_PRESET} publicId={logoUrl}>
                <Transformation width="80" height="80" gravity="faces" crop="fill" />
                <Transformation width="80" height="80" gravity="faces" crop="scale" />
              </CloudinaryImage>
            ) : (
              <Image
                src="https://res.cloudinary.com/dfbb6rcun/image/upload/v1570529082/assets/agency-avatar.png"
                width="80px"
              />
            )}
          </button>
        </div>
      )}
      <Navigation redirect={redirect} navSections={navSections} pathname={pathname} />
    </div>
  )
}

export default subscribe([SupportStaffProvider], ({ isValidSupportStaff, isAccessAllowed }: any) => ({
  isValidSupportStaff,
  isAccessAllowed,
}))(Sidebar)
